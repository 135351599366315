// Import mixins
@import 'components/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-radio-button-wrapper-disabled {
    // border-color: $gray-6 !important;
    background: $dark-gray-5 !important;
    color: $dark-gray-3 !important;
  }

  .ant-radio-group {
    .ant-radio {
      &-button {
        &-wrapper {
          color: $gray-7;

          &-checked {
            color: $goldprimary;
          }

          &:hover,
          &:active {
            background: $dark-gray-6;
            color: $gold;
          }
        }
      }
    }
  }
}